import HouseStatus from 'types/houseStatus';

function getHouseStatusColor(status: HouseStatus) {
    return {
        [HouseStatus.AVAILABLE]: 'var(--Green)',
        [HouseStatus.BOOKED]: 'var(--Blue)',
        [HouseStatus.UNAVAILABLE]: 'var(--Red)',
    }[status];
}

export default getHouseStatusColor;
