import React, { ReactNode } from 'react';
import Grid from './styles';

interface Props {
    children: ReactNode;
}

export default function HousesGrid({ children }: Props) {
    return <Grid>{children}</Grid>;
}
