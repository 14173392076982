import { HOUSES_ENDPOINT } from 'constants/api';
import useFetch, { State } from 'hooks/useFetch';
import House from 'types/house';

interface HousesResponse extends State {
    data: House[];
}

export default function GetHouses(): HousesResponse {
    const result = useFetch(HOUSES_ENDPOINT);
    return result;
}
