import Tag from 'components/Tag/styles';
import OriginalButton from 'components/Button/styles';
import styled, { css } from 'styled-components';

export const Card = styled.div<{ isAvailable: boolean }>`
    width: 218px;
    border-radius: 5px;
    overflow: hidden;
    background: white;
    box-sizing: border-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
    position: relative;

    ${Tag} {
        position: absolute;
        left: 8px;
        top: 8px;
    }

    ${(props) =>
        props.isAvailable &&
        css`
            ::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid transparent;
                border-radius: 5px;
            }
            :hover {
                cursor: pointer;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                ::after {
                    border-color: #333333;
                }
            }
        `}
`;

export const Button = styled(OriginalButton)`
    width: 100%;
    height: 40px;
`;

export const PlaceholderWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 140px;
    background-color: var(--Mercury);
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        color: #8a8a8a;
    }
`;

export const Image = styled.img`
    display: block;
    width: 100%;
    height: 140px;
`;

export const TextContainer = styled.div`
    height: calc(100% - 140px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 16px;
`;

export const TextContainerTop = styled.div`
    margin-bottom: 8px;
`;

export const TextContainerBottom = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
`;

export const HouseID = styled.h4`
    color: var(--DustGrey);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
`;

export const Name = styled.h1`
    font-style: italic;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
`;

export const DaysBooked = styled.div`
    display: flex;
    align-items: center;
    color: var(--Green);
    font-size: 14px;
    svg {
        margin-right: 8px;
    }
`;
