import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
  --Alabaster: #F7F7F7;
  --DisabledGrey: #B2B2B2;
  --DustGrey: #999999;
  --Mercury: #E7E7E7;
  --Green: #11A960;
  --Blue: #2C64B7;
  --Red: #E02323;
}
body{
  font-family: 'Source Sans Pro', sans-serif;
  overflow-x: hidden;
  margin: 0;
  background-color: var(--Alabaster);
}

h1,h2,h3,h4,h5,h6{
  margin:0;
}

button{
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  :disabled{
    cursor: unset;
  }
}
`;

export default GlobalStyle;
