import getHouses from 'api/getHouses';
import House from 'components/House';
import HousesGrid from 'components/HousesGrid';
import React from 'react';
import HouseType from 'types/house';
import HouseListLoading from './Loading';

const HousesList: React.FC = () => {
    const { data: housesList, loading, error } = getHouses();
    if (loading) return <HouseListLoading />;
    if (error) return <p>Error</p>;
    return (
        <HousesGrid>
            {housesList.map(({ id, name, image, bookable, booked }: HouseType) => (
                <House
                    key={id}
                    id={id}
                    name={name}
                    image={image}
                    bookable={bookable}
                    booked={booked}
                />
            ))}
        </HousesGrid>
    );
};

export default HousesList;
