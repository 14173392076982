import Tag from 'components/Tag';
import React, { useState } from 'react';
import HouseType from 'types/house';
import { BiCheckCircle } from 'react-icons/bi';
import { IoImageOutline } from 'react-icons/io5';
import HouseStatus from 'types/houseStatus';
import getHouseStatus from './getHouseStatus';
import getHouseStatusTagColor from './getHouseStatusColor';
import {
    Card,
    PlaceholderWrapper,
    HouseID,
    Image,
    Name,
    TextContainer,
    TextContainerTop,
    TextContainerBottom,
    DaysBooked,
    Button,
} from './styles';

export default function House({ id, name, image, bookable, booked }: HouseType) {
    const status = getHouseStatus(booked, bookable);
    const tagColor = getHouseStatusTagColor(status);

    const isBooked = status === HouseStatus.BOOKED;
    const isAvailable = status === HouseStatus.AVAILABLE;
    const isUnavailable = status === HouseStatus.UNAVAILABLE;

    const buttonLabel = isUnavailable ? 'Not Bookable' : 'Book';

    const [showImgPlaceholder, setShowImgPlaceholder] = useState<boolean>(true);

    return (
        <Card isAvailable={isAvailable}>
            <Tag color={tagColor}>{status}</Tag>
            {showImgPlaceholder && (
                <PlaceholderWrapper>
                    <IoImageOutline size={46} />
                </PlaceholderWrapper>
            )}
            <Image
                src={image}
                alt="House"
                loading="lazy"
                onLoad={() => setShowImgPlaceholder(false)}
            />
            <TextContainer>
                <TextContainerTop>
                    <HouseID>Id: {id}</HouseID>
                    <Name>{name}</Name>
                </TextContainerTop>
                <TextContainerBottom>
                    {isBooked ? (
                        <DaysBooked>
                            <BiCheckCircle size={18} /> Booked for {booked} days
                        </DaysBooked>
                    ) : (
                        <Button disabled={isUnavailable}>{buttonLabel}</Button>
                    )}
                </TextContainerBottom>
            </TextContainer>
        </Card>
    );
}
