import styled, { css } from 'styled-components';

const Button = styled.button`
    background-color: var(--Blue);
    color: white;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 900;

    ${(props) =>
        props.disabled &&
        css`
            background-color: var(--DisabledGrey);
        `}
`;

export default Button;
