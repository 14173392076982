import styled from 'styled-components';

const Grid = styled.div`
    max-width: 1280px;
    margin: auto;
    margin-top: 88px;
    gap: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export default Grid;
