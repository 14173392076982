import React from 'react';
import ContentLoader from 'react-content-loader';

export default function Skeleton() {
    return (
        <ContentLoader
            speed={2}
            width={218}
            height={258}
            viewBox="0 0 218 258"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
        >
            <path d="M 0 0 h 218 v 140 H 0 z M 16 159 h 98 v 12 H 16 z M 16 176 h 132 v 14 H 16 z M 16 202 h 187 v 41 H 16 z" />
        </ContentLoader>
    );
}
