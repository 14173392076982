import styled from 'styled-components';

const Tag = styled.div`
    background-color: ${(props) => props.color};
    border-radius: 999px;
    width: fit-content;
    padding: 4px 8px;
    color: var(--Alabaster);
    font-weight: 700;
    font-size: 12px;
`;

export default Tag;
