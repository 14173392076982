import React from 'react';
import HouseSkeleton from 'components/House/Skeleton';
import HousesGrid from 'components/HousesGrid';

export default function HouseListLoading() {
    return (
        <HousesGrid>
            {[...Array(20).keys()].map((key) => (
                <HouseSkeleton key={key} />
            ))}
        </HousesGrid>
    );
}
