import React, { ReactNode } from 'react';
import TagStyle from './styles';

interface Props {
    children: ReactNode;
    color?: string;
}

const defaultProps = {
    color: 'var(--blue)',
};

export default function Tag({ children, color }: Props) {
    return <TagStyle color={color}>{children}</TagStyle>;
}

Tag.defaultProps = defaultProps;
